@layer tailwind {
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
}

/* You can add global styles to this file, and also import other style files */

.p-card-content {
  padding: 0px !important;
}

p-menu > div {
  border: 0px !important;
  width: fit-content !important;
}

p-menu {
  width: fit-content !important;
}

.p-menuitem-link-active {
  & .p-menuitem-text,
  .p-menuitem-icon {
    color: #52abc7 !important;
  }
}

.p-multiselect,
.p-dropdown {
  @apply w-full;
}

p-overlay > div {
  max-width: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
}

.p-dropdown-items {
  padding: 0px !important;
}

.p-dropdown {
  width: 100% !important;
}

.p-calendar {
  @apply w-full;
}

/* Override loader animation from primeNG */
.custom-spinner .p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: #00ff00;
}

.p-tabview-panels {
  padding-left: 0;
  padding-right: 0;
}
