// Original mytheme/variables
// For reference only, not to be used directly
// @import 'primeng-sass-theme/themes/mytheme/variables';

//
// Load custom fonts
//
@import '@fontsource/poppins/400.css';

//
// Color tints
//

$petrolColor: #062a30;

$accentColor50: #bffcb5;
$accentColor100: #00ff00;
$accentColor200: #5cc73b;
$accentColor300: #3d8c26;
$accentColor400: #215212;
$accentColor500: #213324;

$primaryColor50: #a3e0f2;
$primaryColor100: #52abc7;
$primaryColor200: #387d91;
$primaryColor300: #295c6e;
$primaryColor400: #1c3d4a;
$primaryColor500: #0f262e;

$greyColor100: #f8f8f8;
$greyColor200: #e0e0e0;
$greyColor300: #c8c8c8;
$greyColor400: #888888;
$greyColor500: #707070;
$greyColor600: #505050;
$greyColor700: #383838;
$greyColor800: #282828;
$greyColor900: #101010;

//
// Colors
//

/// Primary Colors
$primaryColor: $primaryColor100;
$primaryDarkColor: $primaryColor200;
$primaryDarkerColor: $primaryColor300;
$primaryDarkestColor: $primaryColor400;
$primaryContentColor: #ffffff;
$primaryTextColor: $primaryContentColor;

/// Secondary Colors
$secondaryColor: #607d8b;
$secondaryDarkColor: darken($secondaryColor, 10%);
$secondaryDarkerColor: darken($secondaryColor, 20%);
$secondaryContentColor: #ffffff;
// $secondaryColor: $accentColor200;
// $secondaryDarkColor: $accentColor300;
// $secondaryDarkerColor: $accentColor400;
// $secondaryContentColor: #ffffff;

/// Base color of page, used for blank backgrounds
$baseColor100: #ffffff;
$baseColor200: darken($baseColor100, 4%);
$baseColor300: darken($baseColor100, 8%);
$baseContent: #1f2937; // Foreground content color to use on base color

// Need to define these for the theme to work
// colors are defined in here
@import 'primeng-sass-theme/themes/mytheme/variables/general';

/// Status Colors
$infoColor: #3abef7;
$infoContentColor: #012b3e;
$successColor: #37d39a;
$successContentColor: #013321;
$warningColor: #fabd22;
$warningContentColor: #382800;
$errorColor: #e4677e;
$errorContentColor: #470000;

/// Accent Colors
$helpColor: #9c27b0;
$helpContentColor: #ffffff;
$accentColor: #00ff00;
$accentContentColor: #000000;

// Other Variables
@import 'primeng-sass-theme/themes/mytheme/variables/form';
@import 'primeng-sass-theme/themes/mytheme/variables/button';
@import 'primeng-sass-theme/themes/mytheme/variables/panel';
@import 'primeng-sass-theme/themes/mytheme/variables/_data';

// borders in confirm dialog
@import 'primeng-sass-theme/themes/mytheme/variables/_overlay';

$dialogHeaderBorder: 0 none;
$dialogFooterBorder: 0 none;

@import 'primeng-sass-theme/themes/mytheme/variables/_message';
@import 'primeng-sass-theme/themes/mytheme/variables/_menu';
@import 'primeng-sass-theme/themes/mytheme/variables/_media';
@import 'primeng-sass-theme/themes/mytheme/variables/_misc';

//
// Override primeng variables
//

$fontFamily: Poppins Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

/// Outline color of a focused element and Primary Button
$focusOutlineColor: $accentColor;
$focusShadow: 0 0 0 0.1rem $focusOutlineColor;

$inputBorder: 2px solid #ced4da;
$inputErrorBorderColor: $errorColor $errorColor $errorColor $errorColor;

/// Secondary Button
$secondaryButtonBg: $secondaryColor;
$secondaryButtonTextColor: $secondaryContentColor;
$secondaryButtonBorder: 1px solid $secondaryColor;
$secondaryButtonHoverBg: $secondaryDarkColor;
$secondaryButtonTextHoverColor: $secondaryContentColor;
$secondaryButtonHoverBorderColor: $secondaryDarkColor;
$secondaryButtonActiveBg: $secondaryDarkerColor;
$secondaryButtonTextActiveColor: $secondaryContentColor;
$secondaryButtonActiveBorderColor: $secondaryDarkerColor;
// $secondaryButtonFocusShadow: 0 0 0 0.2rem $accentColor;

// rest of the theme
@import 'primeng-sass-theme/theme-base/_components';

//
// set CSS Variables so they can be used in tailwindCSS
//

@function toHSLValues($color) {
  @return round(calc(hue($color) / 1deg)) + ' ' + round(saturation($color)) + ' ' + round(lightness($color));
}

:root {
  // Primary colors
  --p: #{toHSLValues($primaryColor)};
  --p200: #{toHSLValues($primaryDarkColor)};
  --p300: #{toHSLValues($primaryDarkerColor)};
  --p400: #{toHSLValues($primaryDarkestColor)};
  --pc: #{toHSLValues($primaryContentColor)};

  // Secondary colors
  --s: #{toHSLValues($secondaryColor)};
  --s200: #{toHSLValues($secondaryDarkColor)};
  --s300: #{toHSLValues($secondaryDarkerColor)};
  --sc: #{toHSLValues($secondaryContentColor)};

  // Base colors
  --b100: #{toHSLValues($baseColor100)};
  --b200: #{toHSLValues($baseColor200)};
  --b300: #{toHSLValues($baseColor300)};
  --bc: #{toHSLValues($baseContent)};

  // Status colors
  --in: #{toHSLValues($infoColor)};
  --inc: #{toHSLValues($infoContentColor)};
  --su: #{toHSLValues($successColor)};
  --suc: #{toHSLValues($successContentColor)};
  --wa: #{toHSLValues($warningColor)};
  --wac: #{toHSLValues($warningContentColor)};
  --er: #{toHSLValues($errorColor)};
  --erc: #{toHSLValues($errorContentColor)};

  // Accent colors
  --he: #{toHSLValues($helpColor)};
  --hec: #{toHSLValues($helpContentColor)};
  --a: #{toHSLValues($accentColor)};
  --ac: #{toHSLValues($accentContentColor)};

  // other variables
  --font-family: #{$fontFamily};
}
